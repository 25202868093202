import { template as template_1c03a4c7efb64b0992350855e3f818b8 } from "@ember/template-compiler";
const WelcomeHeader = template_1c03a4c7efb64b0992350855e3f818b8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
