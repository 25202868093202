import { template as template_1a1d7d0719e14d4086f62b173b0fee7e } from "@ember/template-compiler";
const EmptyState = template_1a1d7d0719e14d4086f62b173b0fee7e(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
