import { template as template_d21278a5e8ff488a96e7b2f49927ffdb } from "@ember/template-compiler";
const FKControlMenuContainer = template_d21278a5e8ff488a96e7b2f49927ffdb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
