import { template as template_598c208412644e68a6c24d4dce4860c8 } from "@ember/template-compiler";
const FKLabel = template_598c208412644e68a6c24d4dce4860c8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
