import { template as template_d720eaf88f2147b9b2f42eb2227bd314 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_d720eaf88f2147b9b2f42eb2227bd314(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
