import { template as template_d39e3f1e6c0b46b8b6edf192040eefd4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_d39e3f1e6c0b46b8b6edf192040eefd4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
