import { template as template_cb3028200bb04f0cb1ce58c96a2c4be5 } from "@ember/template-compiler";
const FKText = template_cb3028200bb04f0cb1ce58c96a2c4be5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
